import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JsonConfigService } from '../../services/config/json-config.service';
import { BackendConfigService } from '../../services/config/backend-config.service';
import { AuthService } from '../../services/auth/auth.service';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'app-user',
  styleUrls: [ './user.component.css' ],
  templateUrl: './user.component.html'
})
export class UserComponent implements OnInit {

  username = null;
  footer = '';
  showAlertsColumn = false;

  constructor(
    private config: JsonConfigService,
    private backendConfigService: BackendConfigService,
    private dataService: DataService,
    private authService: AuthService,
    private router: Router
  ) {
    backendConfigService.get('SHOW_ALERTS').subscribe(rawResponse => {
      if (rawResponse === null) {
        return;
      }

      const parsedResponse = typeof rawResponse=='object' ? rawResponse : JSON.parse(rawResponse);
      this.showAlertsColumn = (parsedResponse.result === 'true');
    });
  }

  ngOnInit() {
    const footer = this.config.get('footer');
    this.authService.authentication$.subscribe(a => {
      this.username = (a && a.user) ? a.user.first_name || a.user.username : '';
    });

    this.dataService.version.subscribe(v => {
      this.footer =
        footer.replace(/##DASHBOARDVERSION##/g, v.version).replace(/##ENGINEVERSION##/g, v.engine_version);
    });
  }

  public isLinkActive(url) {
    const charPos = this.router.url.indexOf('?');
    const cleanUrl = charPos !== -1 ? this.router.url.slice(0, charPos) : this.router.url;
    return (cleanUrl === url);
  }
}
