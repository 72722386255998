import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { JsonConfigService } from '../../services/config/json-config.service';
import { AuthService } from '../../services/auth/auth.service';
import { AlertsService } from '../../services/alerts/alerts.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackendConfigService } from '../../services/config/backend-config.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  forgotPasswordForm: FormGroup;
  submitted = false;

  model: {
    user: string,
  } = { user: '' };
  returnUrl: string;
  message: string;

  messages: Array<string> = [];

  siteKey: string;

  disabled = false;
  error = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private cookie: CookieService,
    public config: JsonConfigService,
    public alertService: AlertsService,
    private formBuilder: FormBuilder,
    private backendConfigService: BackendConfigService
  ) {

    this.forgotPasswordForm = this.formBuilder.group({
      // validates date format yyyy-mm-dd
      user: [ '', [ Validators.required ] ]
    });
    backendConfigService.get('RECAPTCHA_SITE_KEY').subscribe(rawResponse => {
      if (rawResponse === null) {
        return;
      }

      const parsedResponse = typeof rawResponse=='object' ? rawResponse : JSON.parse(rawResponse);
      this.siteKey = parsedResponse.result;

      if (this.siteKey) {
        this.forgotPasswordForm = this.formBuilder.group({
          // validates date format yyyy-mm-dd
          user: [ '', [ Validators.required ] ],
          recaptchaReactive: [ null, Validators.required ]
        });
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.forgotPasswordForm.controls;
  }

  ngOnInit() {
    this.authService.logout();
    // this.siteKey = this.config.get('SITE_KEY');
    this.model.user = '';
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? decodeURI(this.route.snapshot.queryParams['returnUrl']) : '/';


    document.body.classList.add('cloud');
    document.body.classList.add('vcenter');
    document.body.classList.remove('modal-open');
    document.body.style.backgroundImage = 'url("' + this.config.loginBg + '")';
  }

  ngOnDestroy() {
    document.body.style.backgroundImage = '';
    document.body.classList.remove('cloud');
    document.body.classList.remove('vcenter');
    document.body.classList.remove('modal-open');
  }

  resetPassword() {
    this.disabled = true;
    this.error = false;

    this.authService.forgotPassword(
      this.model.user
    ).subscribe(
      res => {
        this.error = false;
        this.message = `Reset link is sent to your email.`;
        this.disabled = false;
      },
      err => {
        this.error = true;
        this.disabled = false;
        this.messages = Object.values(err.error)[0] as Array<string>;
      });
  }

  clearMessage() {
    if (this.message) {
      this.message = '';
    }
    if (this.messages.length) {
      this.messages = [];
    }
  }

  resolved(captchaResponse: string, res) {
    console.log(`Resolved response token: ${captchaResponse}`);

  }
}
