import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/timer';
import { AuthService } from '../../auth/auth.service';

export function existingUsernameValidator(userService: AuthService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    const debounceTime = 500; // milliseconds
    return Observable.timer(debounceTime).switchMap(() => {
      return userService.getUserByUsername(control.value).map(
        res => {
          return (res && res.count > 0) ? { 'usernameExists': true } : null;
        }
      );
    });
  };
}

@Directive({
  selector: '[usernameExists][formControlName],[usernameExists][formControl],[usernameExists][ngModel]',
  providers: [ { provide: NG_ASYNC_VALIDATORS, useExisting: ExistingUsernameValidatorDirective, multi: true } ]
})
export class ExistingUsernameValidatorDirective implements AsyncValidator {
  constructor(private userService: AuthService) {
  }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return existingUsernameValidator(this.userService)(control);
  }
}
