import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export interface TokenResponse {
  token: string;
  is_first_user: boolean;
}

export interface User {
  id: string;
  username: string;
  wrap: boolean;
  first_name: string;
}

export interface Authentication {
  user: User;
  token: string;
}

@Injectable()
export class AuthService {

  private tokenKey = 'x-appz-dashboard-token';

  constructor(private http: HttpClient) {
  }

  private _authentication = new BehaviorSubject<Authentication>(null);

  get authentication(): Authentication {
    return this._authentication.value;
  }

  get authentication$(): Observable<Authentication> {
    const savedToken = localStorage.getItem(this.tokenKey);
    if (this._authentication.value == null && savedToken != null) {
      return this.loginWithToken(savedToken, false);
    } else {
      return this._authentication.asObservable();
    }
  }

  checkSavedToken() {
    const savedToken = localStorage.getItem(this.tokenKey);
    return savedToken;
  }

  loginWithCredentials(username: string, password: string): Observable<Authentication> {
    const body = { username, password };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(environment.apiUrl + '/auth/rest/login/', body, { headers })
      .pipe(flatMap((tokenResponse: TokenResponse) => this.loginWithToken(tokenResponse.token, tokenResponse.is_first_user)));
  }

  loginWithToken(token: string, is_first_user: boolean = false): Observable<Authentication> {
    return this.getUser(token)
      .pipe(
        map((user: User) => ({ user: user, token: token })),
        tap(authentication => {
          this.setAuthentication(authentication, is_first_user);
        }),
      );
  }

  logout() {
    this.setAuthentication(null, false);
  }

  public updateWrapOption(wrap: boolean): Observable<any> {
    const params = new HttpParams()
      .set('wrap', wrap.toString());

    return this.http.get(environment.apiUrl + '/user-wrap/', {
      params: params
    });
  }

  public activateUser(token: string, email: string, username: string, password: string): Observable<any> {
    const body = { token, email, username, password };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(environment.apiUrl + '/auth/activate/', body, { headers })
      .pipe(flatMap((tokenResponse: TokenResponse) => this.loginWithToken(tokenResponse.token, tokenResponse.is_first_user)));
  }

  public resetPassword(token: string, user: string, password: string): Observable<any> {
    const body = { token, user, password };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(environment.apiUrl + '/auth/reset-password/', body, { headers });
  }

  public forgotPassword(user: string): Observable<any> {
    const body = { user };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(environment.apiUrl + '/admin/auth/forgot-password/', body, { headers });
  }

  public getUserByUsername(username: string): Observable<any> {

    const body = { username };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(`${environment.apiUrl}/auth/user/`, body, { headers });
  }

  public getUserByEmail(username: string): Observable<any> {
    const body = { username };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(`${environment.apiUrl}/auth/user/`, body, { headers });
  }

  private getUser(token: string): Observable<User> {
    const headers = new HttpHeaders().append('Authorization', `Token ${token}`);
    return this.http.get<User>(environment.apiUrl + '/user/', { headers: headers });
  }

  private setAuthentication(authentication: Authentication, is_first_user: boolean) {
    if (authentication === null) {
      localStorage.removeItem(this.tokenKey);
      localStorage.removeItem('user-wrap');
    } else {
      localStorage.setItem(this.tokenKey, authentication.token);
      localStorage.setItem('user-wrap', authentication.user.wrap.toString());
    }
    localStorage.setItem('is_first_user', is_first_user ? 'true' : 'false');
    this._authentication.next(authentication);
  }

}
