import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { JsonConfigService } from '../../services/config/json-config.service';
import { AuthService } from '../../services/auth/auth.service';
import { AlertsService } from '../../services/alerts/alerts.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../services/auth/validators/must-match.validator';
import { CustomValidators } from '../../services/auth/validators/custom.validator';
import { BackendConfigService } from '../../services/config/backend-config.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  resetPasswordForm: FormGroup;
  submitted = false;

  model: {
    token: string,
    user: string,
    password: string,
  } = { token: '', user: '', password: '' };
  returnUrl: string;
  message: string;

  messages: Array<string> = [];

  siteKey: string;

  disabled = false;
  error = false;

  private routeSub: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private cookie: CookieService,
    public config: JsonConfigService,
    public alertService: AlertsService,
    private formBuilder: FormBuilder,
    private backendConfigService: BackendConfigService
  ) {
    const groupFields = {
      // validates date format yyyy-mm-dd
      user: [ '', [ Validators.required ] ],
      password: [ '', [
        Validators.required,
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true
        }),
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8) ] ],
      confirmPassword: [ '', Validators.required ]
    };
    const validators = {
      validator: MustMatch('password', 'confirmPassword')
    };

    this.resetPasswordForm = this.formBuilder.group(groupFields, validators);

    backendConfigService.get('RECAPTCHA_SITE_KEY').subscribe(rawResponse => {
      if (rawResponse === null) {
        return;
      }

      const parsedResponse = typeof rawResponse=='object' ? rawResponse : JSON.parse(rawResponse);
      this.siteKey = parsedResponse.result;

      if (this.siteKey) {
        groupFields['recaptchaReactive'] = [ null, Validators.required ];
        this.resetPasswordForm = this.formBuilder.group(groupFields, validators);
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetPasswordForm.controls;
  }

  ngOnInit() {
    this.model.token = '';
    this.model.user = '';
    this.model.password = '';

    this.routeSub = this.route.params.subscribe(params => {
      this.model.token = params['token'];
    });

    document.body.classList.add('cloud');
    document.body.classList.add('vcenter');
    document.body.classList.remove('modal-open');
    document.body.style.backgroundImage = 'url("' + this.config.loginBg + '")';
  }

  ngOnDestroy() {
    document.body.style.backgroundImage = '';
    document.body.classList.remove('cloud');
    document.body.classList.remove('vcenter');
    document.body.classList.remove('modal-open');

    this.routeSub.unsubscribe();
  }

  reset() {
    this.disabled = true;
    this.error = false;

    this.authService.resetPassword(
      this.model.token,
      this.model.user,
      this.model.password
    ).subscribe(
      res => {
        this.error = false;
        this.message = `Your password is reset.`;
        setTimeout(() => {
          this.disabled = false;
          this.router.navigateByUrl('/login', { replaceUrl: true });
        }, 3000);
      },
      err => {
        this.error = true;
        this.disabled = false;
        this.messages = Object.values(err.error)[0] as Array<string>;
      });
  }

  resolved(captchaResponse: string, res) {
    console.log(`Resolved response token: ${captchaResponse}`);

  }

}
