import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import 'moment/min/locales';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { JsonConfigService } from '../services/config/json-config.service';
import { snapshotManager } from '@datorama/akita';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'body',
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private config: JsonConfigService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter(event => event.key === 'x-appz-dashboard-token'),
      untilDestroyed(this),
    ).subscribe(event => {
      console.log(event);
      if ( event.newValue && !event.oldValue) {
        this.router.navigate(['/']);
      } else {
        this.authService.logout();
      }
    });
    this.title.setTitle(this.config.get('title'));
    moment.locale(navigator.language);
  }
}
