import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/timer';
import { AuthService } from '../auth.service';

export function existingEmailValidator(userService: AuthService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    const debounceTime = 500; // milliseconds
    return Observable.timer(debounceTime).switchMap(() => {
      return userService.getUserByEmail(control.value).map(
        res => {
          return (res && res.count > 0) ? { 'emailExists': true } : null;
        }
      );
    });
  };
}

@Directive({
  selector: '[emailExists][formControlName],[emailExists][formControl],[emailExists][ngModel]',
  providers: [ { provide: NG_ASYNC_VALIDATORS, useExisting: ExistingEmailValidatorDirective, multi: true } ]
})
export class ExistingEmailValidatorDirective implements AsyncValidator {
  constructor(private userService: AuthService) {
  }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return existingEmailValidator(this.userService)(control);
  }
}
