import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { JsonConfigService } from '../../services/config/json-config.service';
import { AuthService } from '../../services/auth/auth.service';
import { AlertsService } from '../../services/alerts/alerts.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../services/auth/validators/must-match.validator';
import { CustomValidators } from '../../services/auth/validators/custom.validator';
import { existingUsernameValidator } from '../../services/auth/validators/exist-username.validator';
import { existingEmailValidator } from '../../services/auth/validators/exist-email.validator';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html'
})
export class ActivateComponent implements OnInit, OnDestroy {

  activateForm: FormGroup;
  submitted = false;

  model: {
    token: string,
    email: string,
    username: string,
    password: string,
  } = { token: '', email: '', username: '', password: '' };
  returnUrl: string;
  message: string;

  messages: Array<string> = [];

  disabled = false;
  error = false;

  private routeSub: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private cookie: CookieService,
    public config: JsonConfigService,
    public alertService: AlertsService,
    private formBuilder: FormBuilder
  ) {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.activateForm.controls;
  }

  get username() {
    return this.activateForm.get('username');
  }

  get email() {
    return this.activateForm.get('email');
  }

  ngOnInit() {
    this.model.username = '';
    this.model.email = '';
    this.model.password = '';
    this.model.token = '';

    this.routeSub = this.route.params.subscribe(params => {
      this.model.token = params['token'];
    });

    this.activateForm = this.formBuilder.group({
      // validates date format yyyy-mm-dd
      username: [ '',
        [ Validators.required ],
        [ existingUsernameValidator(this.authService) ] ],
      email: [ '', [ Validators.required, Validators.email ],
        [ existingEmailValidator(this.authService) ] ],
      password: [ '', [
        Validators.required,
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true
        }),
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8) ]
      ],
      confirmPassword: [ '', Validators.required ]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    document.body.classList.add('cloud');
    document.body.classList.add('vcenter');
    document.body.classList.remove('modal-open');
    document.body.style.backgroundImage = 'url("' + this.config.loginBg + '")';
  }

  ngOnDestroy() {
    document.body.style.backgroundImage = '';
    document.body.classList.remove('cloud');
    document.body.classList.remove('vcenter');
    document.body.classList.remove('modal-open');

    this.routeSub.unsubscribe();
  }

  activate() {
    this.disabled = true;
    this.error = false;

    this.authService.activateUser(
      this.model.token,
      this.model.email,
      this.model.username,
      this.model.password
    ).subscribe(
      res => {
        this.error = false;
        this.message = `${this.model.username} is activated successfully.`;

        const is_first_user = localStorage.getItem('is_first_user') === 'true';
        setTimeout(() => {
          this.disabled = false;

          if (is_first_user) {
            window.location.replace(this.config.getAdminEndpoint());
          }
          this.router.navigateByUrl('', { replaceUrl: true });
        }, 3000);
      },
      err => {
        this.error = true;
        this.disabled = false;
        this.messages = Object.values(err.error)[0] as Array<string>;
      });
  }

}
