import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as tz from 'moment-timezone';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { List } from '../../../models/list';
import { DateRange } from '../../../models/range';
import { ModalDataViewComponent } from '../../modal-data-view/modal-data-view.component';
import { ModalLogViewComponent } from '../../modal-log-view/modal-log-view.component';
import { BaseViewComponent } from '../base-view/base-view.component';

@Component({
  selector: 'app-stream-view',
  templateUrl: './stream-view.component.html',
  styleUrls: [ './stream-view.component.css' ]
})
export class StreamViewComponent extends BaseViewComponent implements OnInit, OnDestroy {

  appList: List = new List;

  params: any = {};

  querySub: Subscription;

  page = 0;

  commits = [];

  period: DateRange = new DateRange(moment().startOf('day'), moment().endOf('day'));


  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    this.querySub && this.querySub.unsubscribe();
  }

  loadData() {
    this.page = 0;
    this.appList = new List;
    this.loader = true;
    this.dataService.getStream(this._query, this._days)
      .pipe(
        map((data: any) => {
          this.convert(data);
          return data;
        })
      )
      .subscribe(
        (data: List) => {
          this.appList = List.fromDTO(data);
          this.initModals();
        },
        null,
        () => this.loader = false
      );
  }

  loadMore() {
    if (this.page < this.appList.total_pages - 1) {
      this.loader = true;
      this.page++;
      this.cd.detectChanges();
      this.dataService.getStream(this._query, this._days, this.page)
        .subscribe(
          list => {
            this.convert(list);
            this.appList.data = this.appList.data.concat(list['data']);
            this.loader = false;
          },
          () => {
            this.loader = false;
            this.cd.detectChanges();
          }
        );
    }
  }

  initModals() {

    this.querySub = this.querySub || this.route.queryParamMap.subscribe(p => {
      if ( p.get('page') && this.page < parseInt(p.get('page'))) {
        this.loader = true;
        this.page++;
        this.cd.detectChanges();
        this.dataService.getStream(this._query, this._days, this.page)
          .subscribe(
            list => {
              this.convert(list);
              this.appList.data = this.appList.data.concat(list['data']);
              this.loader = false;
              this.modalTrackerHandler(p);
            },
            () => {
              this.loader = false;
              this.cd.detectChanges();
            }
          );
      } else {
        this.modalTrackerHandler(p);
      }
    });
  }

  modalTrackerHandler(p) {
    this.modalTracker.execIfNewParam(this.params, 'mlv', p.get('mlv'), (mlv) => {
      this.appList.findTyped(mlv, p.get('env'), p.get('mlvtype'), app => this.openLogModal(app, p.get('mlvtype'), p.get('mlvlevel')));
    });

    this.modalTracker.execIfNewParam(this.params, 'mv', p.get('mv'), (mv) => {
      this.appList.findTyped(mv, p.get('env'), 'deploy', app => this.monitoringViewModal(this.convertApp(app)));
    });

    this.modalTracker.execIfNewParam(this.params, 'dv', p.get('dv'), dv => {
      this.appList.findTyped(dv, p.get('env'), p.get('dvtype'), app => this.openDataModal(app, p.get('dvtype')));
    });

  }

  openLog(app: any, type: string, level: string = null) {
    this.modalTracker.navigate({
      mlv: app.app_id,
      env: app.env,
      v: app.deploy_version || app.build_version || (app[type] && app[type].version),
      mlvlevel: level,
      mlvtype: type,
      page: this.page,
    });
  }

  openLogModal(app: any, type: string, level: string = null) {
    this.dataService.check().subscribe(() => {

      const data = {
        'build_id': app.d.data.build_id,
        'deploy_id': app.d.data.deploy_id,
        'build_number': app.d.build_number
      };

      this.modalTracker.track('mlv').show(ModalLogViewComponent, {
        initialState: {
          mid: 'mlv',
          id: app.app_id,
          app: this.convertApp(app),
          data,
          type,
          level
        },
        ignoreBackdropClick: true
      });

    });
  }

  convert(data) {
    data.data.map(v => {
      if (typeof (v.d.data.data) == 'string') {
        v.d.data.data = JSON.parse(v.d.data.data);
      }
    });

    data.data = data.data.sort((a, b) => {
      const at = a.d.data.end_time ? this.dt(a.d.data.end_time) : this.dt(a.d.data.start_time);
      const bt = b.d.data.end_time ? this.dt(b.d.data.end_time) : this.dt(b.d.data.start_time);
      return moment(bt).diff(moment(at), 'seconds');
    });
  }

  dt(date: string) {
    const parts = (date || 'zzz').split(' ');
    if (parts.length === 2) {
      return parts.join('T') + 'Z';
    }
  }

  buildTime(build: any) {
    return moment(this.dt(build.d.data.end_time)).diff(moment(this.dt(build.d.data.start_time)), 'seconds');
  }

  convertApp(app) {
    return {
      app_name: app.d.data.data.input ? app.d.data.data.input.yaml.app.name : '',
      app_id: app.app_id,
      build_version: app.version,
      build_build_number: app.d.build_number,
      deploy_version: app.version,
      deploy_build_number: app.d.build_number,
      env: app.env,
      timestamp: app.d.data.timestamp,
      deployment: app.deployment
    };
  }

  openData(item: any, type: string) {
    this.modalTracker.navigate({ dv: item.app_id, env: item.env, dvtype: type, page: this.page });
  }

  openDataModal(app: any, type: string) {
    const capp = this.convertApp(app);
    this.dataService.check().subscribe(() => {
      this.modalTracker
        .track('dv')
        .show(ModalDataViewComponent, {
          initialState: {
            mid: 'dv',
            id: app.app_id,
            title: `Related Data from ${capp.app_name} ${capp[type + '_version']}.${capp[type + '_build_number']}`,
            data: JSON.stringify(app.d.data.data)
          },
          ignoreBackdropClick: true
        });
    });
  }

  getCommits(app) {
    this.commits = [];

    const localDate = tz(moment.utc(app.timestamp).clone()).tz('America/Los_Angeles');
    const start = localDate.clone().startOf('day');
    const end = localDate.clone().endOf('day');
    this.period = new DateRange(start, end);
    this.dataService.getBuildCommits(app, this.period.toString())
      .subscribe(
        builds => {
          this.commits = this.generateCommits(builds);
        },
        null,
        () => {
          this.loader = false;
          this.cd.detectChanges();
        }
      );
  }

  generateCommits(builds) {
    let result = [];
    builds.data.forEach(a => result = result.concat(a.commits));
    return result;
  }
}
