import { Component, OnDestroy, OnInit } from '@angular/core';
import { JsonConfigService } from '../../services/config/json-config.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit, OnDestroy {
  constructor(
    public config: JsonConfigService,
  ) {
  }

  ngOnInit() {
    document.body.classList.add('cloud');
    document.body.classList.add('vcenter');
    document.body.style.backgroundImage = 'url("' + this.config.loginBg + '")';
  }

  ngOnDestroy() {
    document.body.style.backgroundImage = '';
    document.body.classList.remove('cloud');
    document.body.classList.remove('vcenter');
  }

}
